<template>
  <div>
    <el-form class="el-form-custom mB20" :model="formData" :inline="true">
      <el-form-item label="监测类型">
        <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="handleSelect">
          <el-option v-for="item in typeRelOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="监测指标">
        <el-select v-model="formData.typeId" placeholder="请选择">
          <el-option v-for="item in targetOptions" :key="item.quotaId" :label="item.quotaName" :value="item.quotaId" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formData.code" placeholder="请输入测点编号" />
        <el-button @click="getData('query')">查询</el-button>
        <el-button @click="resetData" class="reset-btn">
          <!-- <svg class="icon reset-icon" aria-hidden="true">
            <use xlink:href="#iconlujing"></use>
          </svg> -->
          <span>重置</span>
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="batchSet" class="mlbtn" :disabled="!valListFlag" :class="!myOption ? 'disable_icon' : ''">批量设置</el-button>
        <el-button @click="handleSliver" :class="!myOption ? 'disable_icon' : ''">预警推送</el-button>
      </el-form-item>
    </el-form>

    <div class="table-wrapper">
      <div class="tbopt" v-show="isShowHeaderList">
        <el-checkbox-group v-model="checkedTbHeader">
          <div v-for="item in tableHeaderData" :key="item.name" class="tbopt-item">
            <el-checkbox :label="item.name" :disabled="item.disabled">{{ item.name }}</el-checkbox>
          </div>
        </el-checkbox-group>
        <div class="btn-click">
          <el-button @click="handleConfirm">确定</el-button>
          <el-button class="cancel-btn" @click="isShowHeaderList = false">取消</el-button>
        </div>
      </div>

      <!-- stripe -->
      <!-- <el-table :data="tableData" :header-cell-style="{ background: '#04153F', color: '#fff' }" style="width: 100%" :row-class-name="tableRowClassName" :row-key="getRowKey" ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column align="center" type="selection" width="55" :reserve-selection="true"> </el-table-column>
        <el-table-column align="center" label="测点编号" prop="code" :min-width="headW"></el-table-column>
        <el-table-column align="center" label="监测类型" prop="typeGroupName"></el-table-column>
        <el-table-column align="center" label="监测指标" prop="typeName"></el-table-column>
        <el-table-column align="center" label="位置描述" prop="site"></el-table-column>
        <el-table-column align="center" label="是否预警" prop="duration">
          <template slot-scope="scope">
            {{ durationOptions[scope.row.duration] }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="预警区间" prop="interval" min-width="150"></el-table-column>
        <el-table-column align="center" label="操作" :min-width="endW">
          <template slot-scope="scope">
            <span class="operate-txt" :class="disabled ? 'disable_icon' : ''" @click="setThreshold(scope.row.id)"> 设置阈值 </span>
          </template>
        </el-table-column>
      </el-table> -->
      <!-- 新列开始 -->
      <template v-if="headerDetails.length && tableData.length">
        <el-table :data="tableData" :header-cell-style="{ background: '#04153F', color: '#fff' }" style="width: 100%" :row-class-name="tableRowClassName" @header-click="headerClick" :row-key="getRowKey" ref="multipleTable" @select-all="handleSelectionChange" @select="handleSelectionChange" :height="tableHeight">
          <el-table-column type="selection" width="55" :selectable="checkIsSelectable"></el-table-column>
          <el-table-column v-for="(item, index) in headerDetails" :key="index" :label="item.name" :prop="item.value" :width="item.value == 'theoreticalValue' || item.value == 'site' ? 210 : ''" :render-header="renderHeader">
            <template slot-scope="scope">
              <!-- 2022.09.27 要求动态配置的表格表头每一列需要在固定的位置，顺序不可更改 -->
              <!-- 测点编号 -->
              <div v-if="item.value == 'code'">{{ scope.row.code }}</div>
              <!-- 是否预警 -->
              <div v-if="item.value == 'duration'">{{ scope.row.duration == 0 ? "不预警" : "预警" }}</div>
              <!-- 监测类型 -->
              <div v-if="item.value == 'type'">{{ scope.row.type == 1 ? "自动化监测" : "人工监测" }}</div>
              <!-- 监测指标 -->
              <div v-if="item.value == 'quotaName'">
                {{ scope.row.quotaName }}
                <!-- {{ formatterType(scope.row.typeRel) }} -->
              </div>
              <!-- 正向阈值 -->
              <div v-if="item.value == 'positiveValue'">
                {{ scope.row.positiveValue }}
                <!-- <span :class="scope.row.level == 3 ? 'blue-level' : scope.row.level == 2 ? 'yellow-level' : 'red-level'">
                {{ formatLevel(scope.row.level) }}
              </span> -->
              </div>
              <!-- 负向阈值 -->
              <div v-if="item.value == 'negativeValue'">{{ scope.row.negativeValue }}</div>
              <!-- 理论值 -->
              <el-tooltip v-if="item.value == 'theoreticalValue' && scope.row.theoreticalValue.length" class="item" effect="dark" :content="scope.row.theoreticalValue.join()" placement="top">
                <div class="data-out">{{ scope.row.theoreticalValue.join() }}</div>
              </el-tooltip>
              <div class="data-out" v-if="item.value == 'theoreticalValue' && !scope.row.theoreticalValue.length">/</div>

              <!-- 位置描述 -->
              <el-tooltip class="item" effect="dark" :content="scope.row.site" placement="top">
                <div class="data-out" v-if="item.value == 'site'">{{ scope.row.site }}</div>
              </el-tooltip>
              <!-- 处理状态 -->
              <!-- <div v-if="item.value == 'status'">
              <span :class="scope.row.status == '1' ? 'green-level' : 'red-level'">
                {{ formatStauts(scope.row.status) }}
              </span>
            </div> -->
              <!-- 操作 -->
              <div v-if="item.value == 'operate'">
                <span class="see-btn" :class="valListFlag || !myOption ? 'colorNor no-allowed' : ''" @click="setWin(scope.row)">设置</span>
                <!-- <span class="see-btn" v-show="scope.row.status == 1" @click="handleSee(scope.row)">查看</span>
              <span class="handle-btn" v-show="scope.row.status == 0" @click="handleOperate(scope.row)">处理</span>
              <span class="delete-btn" v-show="isAdmin == 1" @click="handleDelete(scope.row)">删除</span> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="opt" :render-header="renderHeader" width="50px"></el-table-column>
        </el-table>
      </template>

      <!-- 新列结束 -->

      <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>


    <el-dialog title="预警推送" :visible.sync="sliverFlag" width="800px" :before-close="handleClose" custom-class="sliver-dia">
      <div class="my-sliver">
        <el-tabs v-model="type" @tab-click="getSliver">
          <el-tab-pane label="邮箱" name="1"></el-tab-pane>
          <el-tab-pane label="短信" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <table class="border-table-seliver" border="1" style="border-collapse: collapse; width: 100%; color: #fff">
        <tr>
          <th style="width: 300px">{{ type == 1 ? "邮箱" : "手机号" }}</th>
          <th>推送级别</th>
          <th style="width: 90px">操作</th>
        </tr>
        <tr v-for="(itm, idx) in sileverList" :key="idx">
          <!-- 部位 -->
          <td>
            <el-input v-model="itm.account" :placeholder="type == 1 ? '请输入邮箱' : '请输入手机号'"></el-input>
          </td>
          <!-- 系统 -->
          <td>
            <el-checkbox v-model="itm.level3" :true-label="1" :false-label="0">重度预警</el-checkbox>
            <el-checkbox v-model="itm.level2" :true-label="1" :false-label="0">中度预警</el-checkbox>
            <el-checkbox v-model="itm.level1" :true-label="1" :false-label="0">轻度预警</el-checkbox>
          </td>
          <!-- 构件/d指标 -->
          <td class="seliver-del pointer" @click="seliverDel(itm, idx)">删除</td>
        </tr>
      </table>
      <div style="text-align: center" class="seliver-bottom">
        <span class="sureBtn add-btn back-btn pointer mR20 font14" @click="seliverAdd">添加</span>
        <span class="backBtn submit-btn pointer mR20 font14" @click="silverSave()">确定</span>
        <span class="sureBtn back-btn pointer font14 " @click="handleClose">取消</span>
      </div>
    </el-dialog>
    <setThreshold v-if="thresholdVis" :flag="flag" :idList="selectList" @close="close" @sureBtn="sureBtn"></setThreshold>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import setThreshold from "./setThreshold";
export default {
  components: {
    CommonPagination,
    setThreshold
  },
  data() {
    return {
      flag: false,
      valList: [],
      selectList: [],
      valListFlag: false,
      isAdmin: true,
      targetId: "",
      thresholdVis: false,
      projectId: sessionStorage.getItem("projectId"),
      level: 1,
      type: "1",
      sileverList: [],
      headW: "50px",
      endW: "40px",
      sliverFlag: false,
      total: 0,
      currentPage: 1,
      disabled: false,
      typeOptions: [],
      targetOptions: [],
      tableData: [],
      formData: { typeGroupId: "", typeId: "" },
      durationOptions: ["不预警", "预警"],
      // 默认选中
      defaultData: ["预警时间", "预警级别", "测点编号"],
      multipleSelection: [],
      totalMultipleSelection: [],
      headerDetails: [],
      checkedTbHeader: [],
      // 是否显示表头配置框
      isShowHeaderList: false,
      tableHeaderData: [],
      // 监测类型
      typeRelOptions: [
        {
          id: "",
          name: "全部"
        },
        {
          id: 1,
          name: "自动化监测"
        },
        {
          id: 2,
          name: "人工监测"
        }
      ],
      // 预警等级
      warningLevelOptions: [
        {
          id: 1,
          name: "重度预警"
        },
        {
          id: 2,
          name: "中度预警"
        },
        {
          id: 3,
          name: "轻度预警"
        }
      ],
      // 处理状态
      statusOptions: [
        {
          id: 0,
          name: "未处理"
        },
        {
          id: 1,
          name: "已处理"
        }
      ],
      tableHeight:0
    };
  },
  created() {
    sessionStorage.setItem("multipleSelectionSingle", JSON.stringify([]));
    this.getTableHeadData();
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  
  },
  mounted() {
    // 官网体验版isAdmin不为1时禁用，正式版为1.isTrue是true时，不禁用。
    // if(sessionStorage.getItem('isAdmin') == 1) {
    this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;
    this.projectId = sessionStorage.getItem("projectId");
    this.disabled = sessionStorage.getItem("isAdmin") == 2 ? true : false;
    // } else {
    // this.disabled = true
    // }
    // this.getPointTypes();
    this.currentPage = Number(sessionStorage.getItem("warningCurrentPage")) || 1;
    console.log("查看返回是否走这里", this.currentPage);

    this.getData();
    this.getTargetOptions();
  },
  methods: {
    sureBtn(obj) {
      console.log("obj", obj);
      this.selectList.splice(0);
      this.valList.splice(0);
      this.valListFlag = false;
      this.$refs.multipleTable.clearSelection();
      this.flag = false;
      this.getData();
      this.close();
    },
    // 编辑
    setWin(item) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.valList.length) {
        // this.$message.error("sssss");
        return;
      }
      // console.log("item", item);
      // this.form.name1 = item.projectName;
      // this.form.name2 = item.targetName;
      // this.form.name3 = item.quotaName;
      // this.form.name4 = item.typeName;

      // this.form.input1 = item.oneUpper;
      // this.form.input11 = item.oneLower;
      // this.form.input2 = item.twoUpper;
      // this.form.input22 = item.twoLower;
      // this.form.input3 = item.threeUpper;
      // this.form.input33 = item.threeLower;

      // this.quota = item.quotaId;
      // this.target = item.targetId;

      //   this.dialogFormVisible = true;
       
      this.selectList = [];
      this.valList = [];
      this.selectList[this.currentPage - 1] = [item];
      this.flag = true;
      this.thresholdVis = true;
    },
    close() {
      this.thresholdVis = false;
    },
    // 格式化 处理状态
    formatStauts(status) {
      let str = "";
      this.statusOptions.forEach((item) => {
        if (item.id == status) {
          str = item.name;
        }
      });
      return str;
    },
    // 格式化 预警级别
    formatLevel(level) {
      let str = "";
      this.warningLevelOptions.forEach((item) => {
        if (item.id == level) {
          str = item.name;
        }
      });
      return str;
    },
    // 格式化 监测类型
    formatterType(num) {
      let str = "";
      this.typeRelOptions.forEach((item) => {
        if (item.id == num) {
          str = item.name;
        }
      });
      return str;
    },
    // 表格头
    renderHeader(h, { column }) {
      console.log("h", h, "column", column);
      let str = "";
      if (column.property === "opt") {
        str = ' <i class="el-icon-s-operation" style="font-size: 18px"></i>';
      }
      // if (column.property === "typeGroupName" || column.property === "typeName" || column.property === "level" || column.property === "typeRel" || column.property === "status") {
      //   str = ' <i class="el-icon-sort" style="font-size: 18px;color: #CCDAFF"></i>';
      // }
      // if (column.property == this.currentSort.value) {
      //   str = ' <i class="el-icon-sort" style="font-size: 18px;color: #2772F0"></i>';
      // }
      return h("div", [
        h("span", {
          domProps: {
            innerHTML: column.label
          },
          style: {
            color: "#d8d8d8",
            fontSize: "16px"
            // marginRight: "10px"
          }
        }),
        h("span", {
          domProps: {
            innerHTML: str
          },
          style: {
            color: "#d8d8d8",
            fontSize: "14px"
            // marginRight: "10px"
          }
        })
      ]);
    },
    // 点击自定义表头字段icon
    headerClick(c, e) {
      console.log("ccccc", c, "eeee", e);
      if (c.property == "opt") {
        this.getTableHeadData();
        this.isShowHeaderList = !this.isShowHeaderList;
      }
      // if (c.property == "timeO" || c.property == "typeName" || c.property == "level" || c.property == "typeRel" || c.property == "status") {
      //   this.headerDetails.forEach((item) => {
      //     if (item.value == c.property) {
      //       this.currentSort.currentSortId = item.id;
      //       this.currentSort.currentSortOrder == "" ? (this.currentSort.currentSortOrder = 1) : this.currentSort.currentSortOrder == 1 ? (this.currentSort.currentSortOrder = -1) : (this.currentSort.currentSortOrder = 1);
      //       this.currentSort.value = item.value;
      //     }
      //   });
      //   this.currentPage = 1;
      //   this.getTableData();
      // }
    },
    // 自定义表头 确定
    handleConfirm() {
      console.log("选中的", this.checkedTbHeader);
      console.log("所有的", this.tableHeaderData);
      let data = [];
      this.tableHeaderData.forEach((item) => {
        if (this.checkedTbHeader.indexOf(item.name) >= 0) {
          item.status = 1;
        } else {
          item.status = 0;
        }
        data.push(item);
      });
      console.log("发送的", data);

      this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/save/operate/${this.projectId}`, data).then((res) => {
        if (res.data.status == 200) {
          // this.headerDetails = res.data.data;
          this.isShowHeaderList = false;
          this.getTableHeadData();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 获取表格表头数据
    getTableHeadData() {
      this.$axios.get(`${this.baseURL}settings/overrun/operate/${this.projectId}`).then((res) => {
        if (res.data.status == 200) {
          console.log("获取表格表头数据------", res);
          let detail = res.data.data;
          let data = [];
          if (detail.length) {
            this.checkedTbHeader = [];
            detail.forEach((item) => {
              if (item.status == 1) {
                this.checkedTbHeader.push(item.name);
                data.push(item);
              }
              // if (item.name == "测点编号" || item.name == "预警时间" || item.name == "预警级别") {
              //   item.disabled = true;
              // }
            });

            this.tableHeaderData = detail;
            this.headerDetails = data;
            console.log("headerDetails", this.headerDetails, " this.tableHeaderData", this.tableHeaderData);
          } else {
            this.checkedTbHeader = this.defaultData;
          }
        }
      });
    },
    // 是否能选择
    checkIsSelectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      console.log("val-select", val, "this.valList", this.valList);
      if (val.length) {
        this.valList[this.currentPage - 1] = JSON.parse(JSON.stringify(val));
      } else {
        this.valList.splice(this.currentPage - 1, 1);
      }

      this.valListFlag = false;
      for (let i = 0; i < this.valList.length; i++) {
        if (this.valList[i] && this.valList[i].length) {
          this.valListFlag = true;
          return;
        }
      }

      // this.toggleSelection(val);
      // this.multipleSelection[this.currentPage - 1] = val;

      // console.log("this.multipleSelection", this.multipleSelection);
    },
    // toggleSelection(rows) {
    //   if (rows.length) {
    //     console.log("row", rows);
    //     this.tableData.forEach((itm, idx) => {
    //       rows.forEach((row) => {
    //         if (row.id == itm.id) {
    //           this.$refs.multipleTable.toggleRowSelection(this.tableData[idx], true);
    //         }

    //         // this.$refs.multipleTable.setCurrentRow(row);
    //       });
    //     });
    //   } else {
    //     console.log("rows2222222", rows);
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    batchSet() {
      // console.log("this.multipleSelection", this.multipleSelection);
      // let n = this.multipleSelection.length;

      // for (let i = 0; i < n; i++) {
      //   if (this.multipleSelection[0].target.staticDynamicSelected != this.multipleSelection[i].target.staticDynamicSelected) {
      //     return this.$message.error("必须选择同类型文件!");
      //   }
      // }
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (!this.valList.length) {
        return;
      }
      this.selectList = this.valList;
      this.flag = false;
      this.thresholdVis = true;

      // if (this.disabled || !this.multipleSelection.length) return;
      // this.targetId = "";
      // sessionStorage.setItem("multipleSelectionSingle", JSON.stringify(this.multipleSelection));

      // this.thresholdVis = true;
      // this.setThreshold(this.multipleSelection[0].id);
    },
    handleSliver() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.sliverFlag = true;
      this.getSliver();
    },
    seliverDel(itm, idx) {
      console.log("itm", itm, "idx", idx);
      this.sileverList.splice(idx, 1);
    },
    seliverAdd() {
      let obj = {
        account: "",
        level1: 0,
        level2: 0,
        level3: 0
      };
      this.sileverList.push(obj);
      console.log("silverlist", this.sileverList);
      this.$forceUpdate();
    },
    silverSave() {
      console.log("this.sileverList", this.sileverList);
      let n = this.sileverList.length,
        arr = [];
      for (let i = 0; i < n; i++) {
        if (this.sileverList[i].account) {
          this.sileverList[i].type = this.type;
          arr.push(this.sileverList[i]);
        }
      }
      this.$axios.post(`${this.baseURL}base/basewarn/save`, { baseWarnInfoList: arr, type: this.type, projectId: this.projectId }).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.sliverFlag = false;
        }
      });
    },
    getSliver() {
      this.$axios.get(`${this.baseURL}base/basewarn/get/${this.projectId}/${this.type}`).then((res) => {
        console.log("sliver", res.data);
        res = res.data;
        if (res.errCode == 200) {
          this.sileverList = res.data;
          this.sileverList = this.sileverList ? this.sileverList : [];
          if (!this.sileverList.length) {
            this.sileverList = [];
            for (let i = 0; i < 2; i++) {
              let obj = {
                account: "",
                level1: 0,
                level2: 0,
                level3: 0
              };
              this.sileverList.push(obj);
            }
            return;
          }
          if (this.sileverList.length == 1) {
            let obj = {
              account: "",
              level1: 0,
              level2: 0,
              level3: 0
            };
            this.sileverList.push(obj);
          }
        }
      });
    },
    handleClose() {
      this.sliverFlag = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    handleSelect(val) {
      console.log("val", val);
      this.targetOptions = [];
      this.getTargetOptions();
    },
    resetData() {
      this.formData = { typeGroupId: "", typeId: "" };
      this.handleCurrentChange(1);
      // this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      console.log("val", val);
      sessionStorage.setItem("warningCurrentPage", val);
      this.getData();
    },
    setThreshold(id) {
      if (this.disabled) return;
      // sessionStorage.setItem("multipleSelectionSingle", JSON.stringify(this.multipleSelection));
      this.targetId = id;
      // this.$router.push("/setDetail/" + id);
      this.thresholdVis = true;
    },
    // getPointTypes() {
    //   this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
    //     res = res.data;
    //     if (res.errCode == 200) {
    //       res.data.unshift({ id: "", name: "全部" });
    //       this.typeOptions = res.data;
    //       this.getTargetOptions();
    //     }
    //   });
    // },
    getTargetOptions() {
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/quota/list?projectId=${this.projectId}&type=${this.formData.typeGroupId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ quotaId: "", quotaName: "全部" });
          this.targetOptions = res.data;
        }
      });
    },
    getData(val) {
      if (val) {
        this.currentPage = 1;
        sessionStorage.setItem("warningCurrentPage", 1);
      }
      this.formData.current = this.currentPage
      this.formData.projectId = this.projectId
      this.formData.size = 10
      this.$axios
        .get(`${this.baseURL}overlimit/setting/list`, {
          params: this.formData
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            this.total = res.data.total;
            let arr = [];
            res.data.records.forEach((e) => {
              // e.target.duration = e.duration;
              // 告警区间（正向(0.5,0.7,0.8)，负向(0.5,0.6,0.7)）
              // e.target.interval = e.clower ? `正向(${e.clower},${e.blower},${e.alower}),负向(${e.dupper},${e.eupper},${e.fupper})` : "-";
              // e.target.positiveValue = e.clower ? `正向(${e.clower},${e.blower},${e.alower})` : "-";
              // e.target.negativeValue = e.dupper ? `负向(${e.dupper},${e.eupper},${e.fupper})` : "-";
              // arr.push(e.target);
              var forwardArr = e.forward ? e.forward.split(",") : []
              var negativeArr =e.negative ?  e.negative.split(",") : []
              var positiveValue = ""
              if(forwardArr[0] || forwardArr[1] || forwardArr[2]){
                positiveValue = "("+(forwardArr[0]  && forwardArr[0] != "/"? forwardArr[0] + "," : "/,") + (forwardArr[1] && forwardArr[1] != "/"? forwardArr[1] + "," : "/,") + (forwardArr[2] && forwardArr[2] != "/"? forwardArr[2] : "/") +")"+ "mm"
              }else{
                positiveValue = "-"
              }
              var negativeValue = ""
              if(negativeArr[0] || negativeArr[1] || negativeArr[2]){
                console.log("dasda",negativeArr[0]);
                negativeValue =  "("+(negativeArr[0] && negativeArr[0] != "/" ? -negativeArr[0] + "," : "/," )+ (negativeArr[1] && negativeArr[1] != "/"? -negativeArr[1] + "," : "/," )+ (negativeArr[2] && negativeArr[2] != "/"? -negativeArr[2] : "/")+")"+ "mm"
              }else{
                negativeValue = "-"
              }
              e["positiveValue"] = positiveValue
              e["negativeValue"] = negativeValue
              arr.push(e);
            });
            this.tableData = arr;
            this.$nextTick(() => {
              this.saveTheSelectionResult();
            });

            // console.log("tabledata", this.tableData);
            // this.$nextTick(() => {
            //   let arr1 = this.totalMultipleSelection[this.currentPage - 1] || [];
            //   console.log("分页切换arr ", arr1, " this.totalMultipleSelection", this.totalMultipleSelection);
            //   if (arr1.length) {
            //     this.toggleSelection(arr1);
            //   }
            // });
          }
        });
    },
    saveTheSelectionResult() {
      console.log("valuelist", this.valList, this.tableData, this.currentPage);
      if (this.valList[this.currentPage - 1]) {
        this.valList[this.currentPage - 1].forEach((val) => {
          this.tableData.forEach((v, i) => {
            console.log("val-for", val, "v-for", v);
            if (val.targetId === v.targetId) {
              this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true);
            }
          });
        });
      }
      if (!this.valList.length) {
        // this.$refs.multipleTable.clearSelection();
        this.tableData.forEach((v, i) => {
          console.log("v-for-clear", v);

          this.$refs.multipleTable.toggleRowSelection(this.tableData[i], false);
        });
      }
    },
    getSize(){
        this.tableHeight = window.innerHeight - 350
    }
  }
  // beforeDestroy() {
  //   sessionStorage.setItem("warningCurrentPage", 1);
  // }
};
</script>

<style lang="scss" scoped>
.data-out {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  padding-top: 20px;
}

.my-sliver ::v-deep .el-tabs__item.is-active,
.my-sliver ::v-deep .el-tabs__item {
  color: #fff;
  width: 90px;
  text-align: center;
  padding: 0;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.my-sliver {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  margin-bottom: 6px;
  position: relative;
  left: -26px;
}

.my-sliver ::v-deep .el-dialog__header .el-dialog__title {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.my-sliver ::v-deep .el-tabs__header {
  margin-bottom: 3px;
}

.my-sliver ::v-deep .el-tabs__nav-wrap::after {
  background-color: rgba(17, 102, 255, 1);
}

.my-sliver ::v-deep .el-tabs__active-bar {
  // height: 5px;
  border-bottom: 5px solid;
  border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;
}

.seliver-del {
  color: #32c5ff;
}

::v-deep button.el-button.is-disabled,
::v-deep button.el-button.is-disabled:focus,
::v-deep button.el-button.is-disabled:hover {
  border: 1px solid #314c98;
  background: none;
  color: #314c98;
  /* background-color: #434F6A !important; */
}
.reset-btn {
  background: none;
  border: 1px solid #2667db;
  &:hover {
    background: none;
    border: 1px solid #2667db;
  }
}
.seliver {
  // padding-bottom: 15px;
  margin-bottom: 20px;
  display: flex;
  height: 32px;

  span {
    display: inline-block;
    height: 100%;
    padding: 0 32px;
    color: #fff;
  }

  .active {
    border-bottom: 5px solid;
    border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;
  }

  .unactive {
    border-bottom: 1px solid #2772f0;
  }

  .seliver-line {
    flex: 1;
    height: 100%;
    border-bottom: 1px solid #2772f0;
  }
}

::v-deep .el-dialog.sliver-dia {
  width: 800px !important;
  border: none;
  .el-radio__label {
    color: #fff;
  }

  .el-input__inner {
    color: #fff;
    border: none;
    background-color: transparent !important;
    font-size: 16px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}

.add-btn {
  background-color: #2667DB;
}

.font14 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  padding: 6px 32px;
}

.seliver-bottom {
  text-align: center;
  margin-top: 62px;
  margin-bottom: 33px;
}

.border-table-seliver {
  border-collapse: collapse;
  border: none;
  margin-bottom: 20px;

  tr {
    width: 100%;
    height: 40px;
  }
}

.border-table-seliver td {
  padding-left: 10px;
  border: 1px solid #3B68A9;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.border-table-seliver th {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  border: 1px solid #3B68A9;
}

.el-table {
  margin-bottom: 35px;
}
::v-deep .el-button.is-disabled {
  border: 1px solid #314c98;
  background: none;
  color: #314c98;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
.common-page.pageMain {
  margin-top: 15px;
}

.table-wrapper {
  margin-top: 25px;
  .see-btn,
  .handle-btn,
  .delete-btn {
    cursor: pointer;
  }
  .no-allowed {
    cursor: not-allowed;
  }
  .el-table .el-table__body-wrapper tbody tr {
    span.delete-btn {
      margin-left: 16px;
    }
    span.red-level {
      color: #d12a36 !important;
    }
    span.yellow-level {
      color: #ffb134 !important;
    }
    span.blue-level {
      color: #1890ff !important;
    }
    span.green-level {
      color: #22a358 !important;
    }
  }

  .tbopt {
    width: 160px;
    position: absolute;
    right: 80px;
    z-index: 99;
    background-image: radial-gradient(circle at 47% 7.4%, #0c104d 0%, #0b2d5a 132%);
    box-shadow: 0 2px 7px 0 rgba(6, 25, 73, 0.53);
    border-radius: 8px;
    line-height: 2;
    padding: 12px 0 20px 0;
    border: 1px solid #204370;
    .tbopt-item {
      padding-left: 15px;
      ::v-deep span.el-checkbox__label {
        font-size: 14px;
        color: #d8d8d8;
      }
    }
    .btn-click {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      border-top: 1px solid #2a3f71;
      button.el-button {
        font-size: 12px;
        padding: 5px 18px;
        margin-top: 20px;
      }
      .cancel-btn {
        background: none;
        border: 1px solid #2667db;
      }
    }
  }
}
.colorNor {
  // color: #4DC8ED;
  color: #7fbdfc;
  font-family: "Industry-Medium ?";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  // color: #00fff5;
}

.colorNo {
  // color: #4DC8ED;
  // color: #7FBDFC;
  font-family: "Industry-Medium ?";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: #00fff5;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .el-tabs__nav-wrap::after{
height: 0;
}
::v-deep .el-tabs__active-bar{
  opacity: 0.7;
  background-image: linear-gradient(90deg, #7689C7 0%, #1027794d 99%);
  border-radius: 4px;
  border-bottom: none !important;
  border-image:none !important;
  height: 5px;
  width: 40px !important;
  background-color: rgba(0, 0, 0, 0);
  left: 25px;
  top: 25px;
}
::v-deep .my-sliver .el-tabs__nav-scroll{
  height: 40px;
}
::v-deep .my-sliver .el-tabs__item{
  border-right:1px solid #3B68A999;
  height: 25px;
  line-height: 25px;
  color: #CCDAFF;
}

::v-deep .my-sliver .el-tabs__item .is-active{
  color: #fff;

}
::v-deep .my-sliver .el-tabs__item:last-child {
  border-right:0px solid #3B68A999;
        }

::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px;
  border:none;
}
::v-deep .el-radio__input.is-checked+.el-radio__label{
  color: #ffffff;
}
</style>
