<template>
  <el-dialog title="阈值设置" :visible.sync="typeDialogVisible" class="my-dialog add-dialog" top="15vh" :before-close="handleTypeDialogClose">
    <!-- <div class="threshold-line"></div> -->
    <div class="point-info">测点信息</div>
    <div class="content" style="margin-top: 10px;" v-if="!flag">
      <el-table :data="dialogTableData" :row-class-name="tableRowClassName1" :header-row-style="headerRowStyle">
        <!-- <el-table-column prop="timeO" class-name="text-left" label="预警时间"></el-table-column> -->
        <el-table-column prop="code" label="测点编号"></el-table-column>
        <el-table-column prop="quotaName" label="监测指标"></el-table-column>
        <el-table-column prop="theoreticalValue" label="理论值">
          <template slot-scope="scope">
            <div v-if="scope.row.theoreticalValue.length && scope.row.theoreticalValue.length > 3">{{ scope.row.theoreticalValue.slice(0, 3).join() + "..." }}</div>
            <div v-else-if="scope.row.theoreticalValue.length && scope.row.theoreticalValue.length <= 3">{{ scope.row.theoreticalValue.join() }}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="single" v-else>
      <div class="info-table">
        <div class="info-item info-item-message">
          <div class="info-item-title">测点编号</div>
          <div class="info-item-content">{{ targetInfo.code }}</div>
        </div>
        <div class="info-item info-item-message">
          <div class="info-item-title">监测类型</div>
          <div class="info-item-content">{{ targetInfo.type == 1 ? "自动化监测" : "人工监测" }}</div>
        </div>
        <div class="info-item info-item-message">
          <div class="info-item-title">监测指标</div>
          <div class="info-item-content">{{ targetInfo.quotaName }}</div>
        </div>
        <div class="info-item info-item-message">
          <div class="info-item-title">单位</div>
          <div class="info-item-content">{{ targetInfo.unit }}</div>
        </div>
        <div class="info-item info-item-message">
          <div class="info-item-title">位置描述</div>
          <div class="info-item-content">{{ targetInfo.site }}</div>
        </div>
        <div class="info-item info-item-message">
          <div class="info-item-title">理论值</div>
          <div class="info-item-content" v-if="targetInfo.theoreticalValue && targetInfo.theoreticalValue.length > 3">{{ targetInfo.theoreticalValue.slice(0, 3).join() + "..." }}</div>
          <div class="info-item-content" v-else-if="targetInfo.theoreticalValue && targetInfo.theoreticalValue.length <= 3">{{ targetInfo.theoreticalValue.join() }}</div>
          <div class="info-item-content" v-else>-</div>
        </div>
      </div>
    </div>
    <div class="point-info mt16" style="border-top: 1px solid rgba(124, 169, 223, 0.5);">
      <span>阈值设置</span>
      <span v-show="!isAddOverrun" @click="isAddOverrun = true">增加超限指标</span>
    </div>
    <div class="single">
      <div class="info-table">
        <div class="info-item">
          <div class="info-item-title">阈值标识线</div>
          <div class="info-item-content2">
            <el-checkbox-group v-model="overrunForm1.thresholdLineGroup">
              <el-checkbox label="一级超限"></el-checkbox>
              <el-checkbox label="二级超限"></el-checkbox>
              <el-checkbox label="三级超限"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-title">数据源</div>
          <div class="info-item-content2">
            <el-select v-model="overrunForm1.dataRel" placeholder="请选择">
              <el-option v-for="item in dataRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-title">阈值</div>
          <div class="info-item-content2">
            <el-select v-model="overrunForm1.thresholdRel" placeholder="请选择">
              <el-option v-for="item in thresholdRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="info-item" v-show="overrunForm1.thresholdRel == 1">
          <div class="info-item-title" style="border-right: 0">正向阈值</div>
          <div class="info-item-content2">
            <div class="chaoxian-input">
              <div>一级超限</div>
              <div><el-input v-model="overrunForm1.zhengxiang1_cx" oninput="value=value.replace(/[^0-9.]/g,'')" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>二级超限</div>
              <div><el-input v-model="overrunForm1.zhengxiang2_cx" oninput="value=value.replace(/[^0-9.]/g,'')"  /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>三级超限</div>
              <div><el-input v-model="overrunForm1.zhengxiang3_cx"  oninput="value=value.replace(/[^0-9.]/g,'')" /></div>
              <div>%</div>
            </div>
          </div>
        </div>
        <div class="info-item" v-show="overrunForm1.thresholdRel == 1">
          <div class="info-item-title" style="border-right: 0; border-top: 0">负向阈值</div>
          <div class="info-item-content2" style="border-top: 0">
            <div class="chaoxian-input">
              <div>一级超限</div>
              <div><el-input v-model="overrunForm1.fuxiang1_cx" oninput="value=value.replace(/[^0-9.]/g,'')" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>二级超限</div>
              <div><el-input v-model="overrunForm1.fuxiang2_cx" oninput="value=value.replace(/[^0-9.]/g,'')" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>三级超限</div>
              <div><el-input v-model="overrunForm1.fuxiang3_cx" oninput="value=value.replace(/[^0-9.]/g,'')" /></div>
              <div>%</div>
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-content3" v-show="!overrunForm1.isShowRelation" @click="overrunForm1.isShowRelation = true">
            <img src="@/assets/images/particular_conditions.png" />
            特殊条件
          </div>
          <div class="info-item-title2" v-show="overrunForm1.isShowRelation">特殊条件</div>
          <div class="info-item-content4" v-show="overrunForm1.isShowRelation">
            <div class="condition-item" style="margin-bottom: 10px">
              <div class="condition-item-title">条件类型</div>
              <el-select class="select1" v-model="overrunForm1.ruleRel" placeholder="请选择">
                <el-option v-for="item in ruleRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="condition-item" v-show="overrunForm1.ruleRel == 1">
              <div class="condition-item-title">持续性</div>
              <el-select class="select2" v-model="overrunForm1.ruleTime" placeholder="请选择">
                <el-option v-for="item in ruleTimeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="condition-item" v-show="overrunForm1.ruleRel == 2">
              <div class="condition-item-title">频繁性</div>
              <el-select class="select2" v-model="overrunForm1.ruleTime" placeholder="请选择">
                <el-option v-for="item in ruleTimeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
              <div class="condition-item-title">内出现</div>
              <el-select class="select2" v-model="overrunForm1.ruleCount" placeholder="请选择">
                <el-option v-for="item in ruleCountList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item-action" v-show="overrunForm1.isShowRelation" @click="overrunForm1.isShowRelation = false">
            <i class="el-icon-delete" style="color: #44b2f0; font-size: 16px"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="single" v-show="isAddOverrun">
      <div class="info-table">
        <div class="info-item">
          <div class="info-item-title">超限关系</div>
          <div class="info-item-content4">
            <el-radio v-model="relation" label="1">且</el-radio>
            <el-radio v-model="relation" label="2">或</el-radio>
          </div>
          <div class="info-item-action">
            <i @click="isAddOverrun = false" class="el-icon-delete" style="color: #44b2f0; font-size: 16px"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="single" v-show="isAddOverrun">
      <div class="info-table">
        <div class="info-item">
          <div class="info-item-title">阈值标识线</div>
          <div class="info-item-content2">
            <el-checkbox-group v-model="overrunForm2.thresholdLineGroup">
              <el-checkbox label="一级超限"></el-checkbox>
              <el-checkbox label="二级超限"></el-checkbox>
              <el-checkbox label="三级超限"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-title">数据源</div>
          <div class="info-item-content2">
            <el-select v-model="overrunForm2.dataRel" placeholder="请选择">
              <el-option v-for="item in dataRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-title">阈值</div>
          <div class="info-item-content2">
            <el-select v-model="overrunForm2.thresholdRel" placeholder="请选择">
              <el-option v-for="item in thresholdRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="info-item" v-show="overrunForm2.thresholdRel == 1">
          <div class="info-item-title" style="border-right: 0">正向阈值</div>
          <div class="info-item-content2">
            <div class="chaoxian-input">
              <div>一级超限</div>
              <div><el-input v-model="overrunForm2.zhengxiang1_cx" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>二级超限</div>
              <div><el-input v-model="overrunForm2.zhengxiang2_cx" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>三级超限</div>
              <div><el-input v-model="overrunForm2.zhengxiang3_cx" /></div>
              <div>%</div>
            </div>
          </div>
        </div>
        <div class="info-item" v-show="overrunForm2.thresholdRel == 1">
          <div class="info-item-title" style="border-right: 0; border-top: 0">负向阈值</div>
          <div class="info-item-content2" style="border-top: 0">
            <div class="chaoxian-input">
              <div>一级超限</div>
              <div><el-input v-model="overrunForm2.fuxiang1_cx" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>二级超限</div>
              <div><el-input v-model="overrunForm2.fuxiang2_cx" /></div>
              <div>%</div>
            </div>
            <div class="chaoxian-input">
              <div>三级超限</div>
              <div><el-input v-model="overrunForm2.fuxiang3_cx" /></div>
              <div>%</div>
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-content3" v-show="!overrunForm2.isShowRelation" @click="overrunForm2.isShowRelation = true">
            <img src="@/assets/images/particular_conditions.png" />
            特殊条件
          </div>
          <div class="info-item-title2" v-show="overrunForm2.isShowRelation">特殊条件</div>
          <div class="info-item-content4" v-show="overrunForm2.isShowRelation">
            <div class="condition-item" style="margin-bottom: 10px">
              <div class="condition-item-title">条件类型</div>
              <el-select class="select1" v-model="overrunForm2.ruleRel" placeholder="请选择">
                <el-option v-for="item in ruleRelList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="condition-item" v-show="overrunForm2.ruleRel == 1">
              <div class="condition-item-title">持续性</div>
              <el-select class="select2" v-model="overrunForm2.ruleTime" placeholder="请选择">
                <el-option v-for="item in ruleTimeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="condition-item" v-show="overrunForm2.ruleRel == 2">
              <div class="condition-item-title">频繁性</div>
              <el-select class="select2" v-model="overrunForm2.ruleTime" placeholder="请选择">
                <el-option v-for="item in ruleTimeList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
              <div class="condition-item-title">内出现</div>
              <el-select class="select2" v-model="overrunForm2.ruleCount" placeholder="请选择">
                <el-option v-for="item in ruleCountList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="info-item-action" v-show="overrunForm2.isShowRelation" @click="overrunForm2.isShowRelation = false">
            <i class="el-icon-delete" style="color: #44b2f0; font-size: 16px"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-form :model="form3" :inline="true" ref="form3" :rules="rule3" label-width="72px">
      <div class="set-con">
        <div class="set-con-item">
          <el-checkbox label="正向阈值" v-model="positiveFlag" @change="positive"></el-checkbox>
          <div class="set-con-val">
            <el-form-item label="轻度预警" prop="positiveLevel3Percent">
              <el-input v-model="form3.positiveLevel3Percent" :disabled="!positiveFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
          <div class="set-con-val">
            <el-form-item label="中度预警" prop="positiveLevel2Percent">
              <el-input v-model="form3.positiveLevel2Percent" :disabled="!positiveFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
          <div class="set-con-val">
            <el-form-item label="重度预警" prop="positiveLevel1Percent">
              <el-input v-model="form3.positiveLevel1Percent" :disabled="!positiveFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
        </div>
        <div class="set-con-item">
          <el-checkbox label="负向阈值" v-model="negativeFlag" @change="negative"></el-checkbox>

          <div class="set-con-val">
            <el-form-item label="轻度预警" prop="negativeLevel3Percent">
              <span class="set-con-char">-</span>
              <el-input v-model="form3.negativeLevel3Percent" :disabled="!negativeFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
          <div class="set-con-val">
            <el-form-item label="中度预警" prop="negativeLevel2Percent">
              <span class="set-con-char">-</span>
              <el-input v-model="form3.negativeLevel2Percent" :disabled="!negativeFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
          <div class="set-con-val">
            <el-form-item label="重度预警" prop="negativeLevel1Percent">
              <span class="set-con-char">-</span>
              <el-input v-model="form3.negativeLevel1Percent" :disabled="!negativeFlag" placeholder="请输入" />
              <span class="unit">%</span>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="point-info point-line mt16 box justify">
        <div>阈值标识线</div>
        <div style="color: #ccc">预警值=理论值*阈值百分比</div>
      </div>
      <div class="threshold-val point-line">
        <el-checkbox v-model="form3.levelThird" true-label="1" false-label="0">轻度预警</el-checkbox>
        <el-checkbox v-model="form3.levelSecond" true-label="1" false-label="0">中度预警</el-checkbox>
        <el-checkbox v-model="form3.levelFirst" true-label="1" false-label="0">重度预警</el-checkbox>
      </div>
    </el-form> -->
    <div class="box threshold-btn">
      <div class="btnSelect2 mR20 pointer" @click="handleSave">确定</div>
      <div class="btnSelect1 pointer" @click="close">取消</div>
    </div>
  </el-dialog>
</template>
<script>
import { formateArr } from "../../../../utils/formArr";
export default {
  data() {
    var checkedForwardLowerLimit = (rule, value, callback) => {
      console.log("value", value);
      if (value === "" || value === null || value === undefined) {
        callback();
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (value.toString().includes(".")) {
        callback("阈值必须为整数");
      } else {
        if (parseInt(value) > 10000) {
          //  parseInt
          callback("阈值不能大于10000");
        } else if (parseInt(value) < 0) {
          callback("阈值必须为正整数");
        } else {
          callback();
        }
      }
    };
    var checkedForwardLowerLimit1 = (rule, value, callback) => {
      console.log("value", value);
      if (value === "" || value === null || value === undefined) {
        callback();
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) > 10000) {
          //  parseInt
          callback("阈值不能大于10000");
        } else if (parseInt(value) < 0) {
          callback("阈值必须为正整数");
        } else {
          callback();
        }
      }
    };
    var checkedForwardYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) < parseInt(this.form3.clower)) {
          //parseInt
          callback("上限不能小于下限");
        } else callback();
      }
    };

    var checkedForwardOrangeUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) < parseInt(this.form3.cupper)) {
          callback("上限不能小于下限");
        } else callback();
      }
    };

    var checkedNegativeYellowLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) < Number(this.form3.fupper))) {
          // console.log('66666666666666666', value, this.form3.fupper);
          callback("下限必须小于上限");
        } else callback();
      }
    };
    var checkedNegativeYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) > Number(this.form3.flower))) {
          // console.log('77777777777', value, this.form3.flower);
          callback("上限必须大于下限");
        } else callback();
      }
    };
    var checkedNegativeOrangeLowerLimit = (rule, value, callback) => {
      // console.log('88888888888888', value, this.form3.flower);
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) < Number(this.form3.flower))) {
          callback("下限必须小于上限");
        } else callback();
      }
    };
    return {
      value111: "",
      dataRelList: [
        {
          id: "1",
          name: "原始数据",
        },
        {
          id: "2",
          name: "基线校正",
        },
        {
          id: "3",
          name: "温度效应分离",
        },
        {
          id: "4",
          name: "车辆荷载",
        },
        {
          id: "5",
          name: "10分钟均值",
        },
        {
          id: "6",
          name: "1小时均值",
        },
        {
          id: "7",
          name: "10分钟均方根",
        },
        {
          id: "8",
          name: "历史绝对值",
        },
      ],
      thresholdRelList: [
        {
          id: "1",
          name: "理论值",
        },
        {
          id: "2",
          name: "历史最大值",
        },
      ],
      ruleRelList: [
        {
          id: "1",
          name: "持续性",
        },
        {
          id: "2",
          name: "频繁性",
        },
      ],
      ruleTimeList: [
        {
          id: "1",
          name: "10分钟",
        },
        {
          id: "2",
          name: "30分钟",
        },
        {
          id: "3",
          name: "60分钟",
        },
      ],
      ruleCountList: [
        {
          id: "1",
          name: "5次",
        },
        {
          id: "2",
          name: "10次",
        },
        {
          id: "3",
          name: "30次",
        },
      ],
      negativeFlag: false,
      positiveFlag: false,
      targetInfo: {},
      targetIds: [],
      types: [],
      form4: { levelFirst: 0, levelSecond: 0, levelThird: 0 },
      typeDialogVisible: true,
      operateType: "multiple",
      form1: {},
      targetId: "",
      disabled: false,
      form2: { duration: 0 },
      form3: {},
      dialogTableData: [],
      rule3: {
        negativeLevel1Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
        negativeLevel2Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
        negativeLevel3Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
        positiveLevel1Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
        positiveLevel2Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
        positiveLevel3Percent: [{ validator: checkedForwardLowerLimit1, trigger: "blur" }],
      },
      overrunForm1: {
        thresholdLineGroup: [],
        dataRel: "1",
        thresholdRel: "1",
        isShowRelation: false,
        ruleRel: "1",
        ruleTime: "1",
        ruleCount: "1",
        zhengxiang1_cx: "",
        zhengxiang2_cx: "",
        zhengxiang3_cx: "",
        fuxiang1_cx: "",
        fuxiang2_cx: "",
        fuxiang3_cx: "",
      },
      overrunForm2: {
        thresholdLineGroup: [],
        dataRel: "1",
        thresholdRel: "1",
        isShowRelation: false,
        ruleRel: "1",
        ruleTime: "1",
        ruleCount: "1",
        zhengxiang1_cx: "",
        zhengxiang2_cx: "",
        zhengxiang3_cx: "",
        fuxiang1_cx: "",
        fuxiang2_cx: "",
        fuxiang3_cx: "",
      },
      relation: "1",
      isAddOverrun: false,
      headerRowStyle: {
        background: '#0B2D5E', 
        color: '#fff',
      },
    };
  },
  props: {
    projectName: {
      type: String,
      default: "设置阈值",
    },
    idList: {
      type: Array,
      default() {
        return [];
      },
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    idList: {
      handler(n, o) {
        console.log("n", n, "o", o);
      },
    },
  },
  created() {
    // console.log(formateArr(this.idList));
  },
  mounted() {
    // this.arr = JSON.parse(sessionStorage.getItem("multipleSelectionSingle"));
    // this.targetId = this.$route.params.id;
    // this.disabled = sessionStorage.getItem("isAdmin") == 2 ? true : false;
    // if (this.targetId) {
    //   this.getData();
    // }
    this.initData();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    positive() {
      console.log(this.positiveFlag, "this.positiveFlag");
      let arr2 = ["positiveLevel1Percent", "positiveLevel2Percent", "positiveLevel3Percent"];
      if (!this.positiveFlag) {
        arr2.forEach((item) => {
          this.form3[item] = "";
        });
      }
    },
    negative() {
      console.log(this.negativeFlag, "this.negativeFlag");
      let arr1 = ["negativeLevel1Percent", "negativeLevel2Percent", "negativeLevel3Percent"];
      if (!this.negativeFlag) {
        arr1.forEach((item) => {
          this.form3[item] = "";
        });
      }
    },
    initData() {
      let arr = formateArr(this.idList);
      this.forArr = arr;
      this.dialogTableData = JSON.parse(JSON.stringify(arr));
      console.log("forArr", arr);
      // flag  true 单测点    false多测点
      if (this.flag) {
        // if (this.idList && this.idList[0] && this.idList[0].length) {
        this.targetInfo = { ...arr[0] };
        this.targetIds = [arr[0].targetId];
        this.types = [arr[0].type];
        this.getOverrunSettings(); //单测点回显
      } else {
        this.targetIds = [];
        this.types = [];
        arr.forEach((item) => {
          this.targetIds.push(item.targetId);
          this.types.push(item.type);
        });
      }

      // this.getThreshApi();
    },
    getOverrunSettings() {
      const params = {
        targetId: this.forArr[0].targetId,
      };
      this.$axios.get(`${this.baseURL}overlimit/setting/info/${this.forArr[0].targetId}`, { params }).then((res) => {
        var overlimitInfo=  res.data.data
        this.relation = overlimitInfo.overrunRel ? overlimitInfo.overrunRel +"" :"1"
        var resData = overlimitInfo.settingsQuotaBOS;
        if (resData[0]) {
          var thresholdPlusArr = resData[0].thresholdPlus.split(",");
          var thresholdMinusArr = resData[0].thresholdMinus.split(",");
          this.overrunForm1 = {
            thresholdLineGroup: this.getThresholdLineGroup(resData[0].thresholdLine),
            dataRel: resData[0].dataRel + "",
            thresholdRel: resData[0].thresholdRel + "",
            isShowRelation: resData[0].ruleRel,
            ruleRel: resData[0].ruleRel ? resData[0].ruleRel + "" : "1",
            ruleTime: resData[0].ruleTime ? resData[0].ruleTime + "" : "1",
            ruleCount: resData[0].ruleCount ? resData[0].ruleCount + "" : "1",
            zhengxiang1_cx: thresholdPlusArr[0],
            zhengxiang2_cx: thresholdPlusArr[1],
            zhengxiang3_cx: thresholdPlusArr[2],
            fuxiang1_cx: thresholdMinusArr[0],
            fuxiang2_cx: thresholdMinusArr[1],
            fuxiang3_cx: thresholdMinusArr[2],
            id: resData[0].id,
          };
        }
        if (resData[1]) {
          this.isAddOverrun = true;
          var thresholdPlusArr = resData[1].thresholdPlus.split(",");
          var thresholdMinusArr = resData[1].thresholdMinus.split(",");
          this.overrunForm2 = {
            thresholdLineGroup: this.getThresholdLineGroup(resData[1].thresholdLine),
            dataRel: resData[1].dataRel + "",
            thresholdRel: resData[1].thresholdRel + "",
            isShowRelation: resData[1].ruleRel,
            ruleRel: resData[1].ruleRel ? resData[1].ruleRel + "" : "1",
            ruleTime: resData[1].ruleTime ? resData[1].ruleTime + "" : "1",
            ruleCount: resData[1].ruleCount ? resData[1].ruleCount + "" : "1",
            zhengxiang1_cx: thresholdPlusArr[0],
            zhengxiang2_cx: thresholdPlusArr[1],
            zhengxiang3_cx: thresholdPlusArr[2],
            fuxiang1_cx: thresholdMinusArr[0],
            fuxiang2_cx: thresholdMinusArr[1],
            fuxiang3_cx: thresholdMinusArr[2],
            id: resData[1].id,
          };
        }
      });
    },
    getThreshApi() {
      // let params = {
      //   targetIds: this.targetIds.join(),
      //   types: this.types.join()
      // };
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/selectSetting?targetIds=${this.forArr[0].targetId}&types=${this.forArr[0].type}`).then((res) => {
        console.log("确定提交详细信息", res);
        if (res.data && res.data.errCode == 200) {
          this.form3 = res.data.data || {};
          let arr1 = ["negativeLevel1Percent", "negativeLevel2Percent", "negativeLevel3Percent"],
            arr2 = ["positiveLevel1Percent", "positiveLevel2Percent", "positiveLevel3Percent"];
          if (res.data.data) {
            for (let i in res.data.data) {
              console.log("res.data.data", res.data.data[i], arr1.includes(i), i);
              if (arr1.includes(i) && res.data.data[i] !== null && res.data.data[i] !== undefined && res.data.data[i] !== "") {
                console.log("negativeFlag=========");
                this.negativeFlag = true;
              }
              if (arr2.includes(i) && res.data.data[i] !== null && res.data.data[i] !== undefined && res.data.data[i] !== "") {
                console.log("positiveFlag=========");

                this.positiveFlag = true;
              }
            }
          }
        } else {
          this.$message.error("数据返回错误！");
        }
      });
    },
    areTypesConsistent(array) {
      if (array.length === 0) {
        return false;
      }
      const firstType = array[0].type;
      for (let i = 1; i < array.length; i++) {
        if (array[i].type !== firstType) {
          return false;
        }
      }
      return true;
    },
    handleSave() {
      var targetIds = [];
      if (this.flag) {
        targetIds = [this.forArr[0].targetId];
      } else {
        if (this.areTypesConsistent(this.forArr)) {
          this.forArr.forEach((item) => {
            targetIds.push(item.targetId);
          });
        }else{
          this.$message({
            message: '请选择相同的监测类型',
            type: 'warning'
          });
          return
        } 
      }
      var params = {
        settingsQuotaBOS: [],
        projectId: sessionStorage.getItem("projectId"),
        targetIds: targetIds,
        typeRel: this.forArr[0].type,
      };
      var thresholdLine = this.getThresholdLine(this.overrunForm1.thresholdLineGroup);
      var thresholdPlus = this.getThresholdPlus(1, this.overrunForm1);
      var thresholdMinus = this.getThresholdPlus(2, this.overrunForm1);
      var bo1 = {
        thresholdLine,
        dataRel: this.overrunForm1.dataRel,
        thresholdRel: this.overrunForm1.thresholdRel,
        thresholdPlus,
        thresholdMinus,
        id: this.overrunForm1.id || "",
      };
      if (this.overrunForm1.isShowRelation) {
        bo1.ruleRel = this.overrunForm1.ruleRel;
        bo1.ruleTime = this.overrunForm1.ruleTime;
        bo1.ruleCount = this.overrunForm1.ruleRel == 2 ? this.overrunForm1.ruleCount : "";
      }
      params.settingsQuotaBOS.push(bo1);
      if (this.isAddOverrun) {
        params.overrunRel = this.relation;
        thresholdLine = this.getThresholdLine(this.overrunForm2.thresholdLineGroup);
        thresholdPlus = this.getThresholdPlus(1, this.overrunForm2);
        thresholdMinus = this.getThresholdPlus(2, this.overrunForm2);
        var bo2 = {
          thresholdLine,
          dataRel: this.overrunForm2.dataRel,
          thresholdRel: this.overrunForm2.thresholdRel,
          thresholdPlus,
          thresholdMinus,
          id: this.overrunForm2.id || "",
        };
        if (this.overrunForm2.isShowRelation) {
          bo2.ruleRel = this.overrunForm2.ruleRel;
          bo2.ruleTime = this.overrunForm2.ruleTime;
          bo2.ruleCount = this.overrunForm2.ruleRel == 2 ? this.overrunForm2.ruleCount : "";
        }
        params.settingsQuotaBOS.push(bo2);
      }

      this.$axios.post(`${this.baseURL}overlimit/setting/save/batch`, params).then((res) => {
        if (res.data.status == 200) {
          // this.typeDialogVisible = false
          this.$emit("close");
          this.$emit("sureBtn");
          this.$message.success("保存成功！");
        }
        console.log("res-save", res);
      });
    },
    // getData() {
    //   this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then((res) => {
    //     res = res.data;
    //     if (res.errCode == 200) {
    //       this.form1 = res.data;
    //     }
    //   });
    //   this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${this.targetId}`).then((res) => {
    //     res = res.data;
    //     if (res.errCode == 200) {
    //       this.form2.duration = res.data.duration ? res.data.duration : 0;
    //       this.form3 = res.data;
    //       this.form4.levelFirst = res.data.levelFirst;
    //       this.form4.levelSecond = res.data.levelSecond;
    //       this.form4.levelThird = res.data.levelThird;
    //     }
    //   });
    // },
    saveData() {
      // this.form3.targetId = this.targetId;
      // this.form3.duration = this.form2.duration;

      // this.form3 = Object.assign(this.form3, this.form4);

      this.form3["targetIds"] = this.targetIds;
      this.form3["types"] = this.types;

      console.log("form3", this.form3);

      this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/v2/save/batch`, this.form3).then((res) => {
        ////   this.$qs.stringify(this.form3)
        console.log("res-保存1", res);

        res = res.data;
        if (res.errCode == 200) {
          this.$emit("sureBtn");
        }
      });

      // console.log("save", this.arr, this.form3);
      // if (this.arr.length) {
      //   let arr1 = [];
      //   this.arr.forEach((item) => {
      //     arr1.push(item.id);
      //   });

      //   this.form3["targetIds"] = arr1.join(",");
      //   console.log("form", this.form3);
      //   this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/save/batch`, this.$qs.stringify(this.form3)).then((res) => {
      //     console.log("res-保存1", res);

      //     res = res.data;
      //     if (res.errCode == 200) this.$router.go(-1);
      //   });
      //   return;
      // }

      // this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/save`, this.$qs.stringify(this.form3)).then((res) => {
      //   res = res.data;
      //   console.log("save返回数据", res);
      //   if (res.errCode == 200) this.$router.go(-1);
      // });
    },
    handleTypeDialogClose() {
      this.$emit("close");
    },
    // 表格条纹颜色
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    tableRowClassName1({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui2";
      } else {
        return "lv2";
      }
    },
    getThresholdLine(thresholdLineGroup) {
      const levelMap = {
        一级超限: 1,
        二级超限: 2,
        三级超限: 3,
      };
      const numericLevels = thresholdLineGroup.map((level) => levelMap[level]);
      const resultString = numericLevels.join(",");
      return resultString;
    },
    getThresholdLineGroup(thresholdLine) {
      if (!thresholdLine) {
        return [];
      }
      const levelMap = {
        1: "一级超限",
        2: "二级超限",
        3: "三级超限",
      };
      const numericLevels = thresholdLine.split(",").map((level) => levelMap[level]);
      return numericLevels;
    },
    getThresholdPlus(type, overrunForm) {
      var thresholdPlus = "";
      if (type == 1) {
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.zhengxiang1_cx ? overrunForm.zhengxiang1_cx + "," : ",";
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.zhengxiang2_cx ? overrunForm.zhengxiang2_cx + "," : ",";
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.zhengxiang3_cx ? overrunForm.zhengxiang3_cx: "";
      }
      if (type == 2) {
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.fuxiang1_cx ? overrunForm.fuxiang1_cx + "," : ",";
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.fuxiang2_cx ? overrunForm.fuxiang2_cx + "," : ",";
        thresholdPlus += overrunForm.thresholdRel == 1 && overrunForm.fuxiang3_cx ? overrunForm.fuxiang3_cx : "";
      }
      return thresholdPlus;
    },
  },
};
</script>
<style lang="scss" scoped>
.set-con {
  margin-top: 20px;
  display: flex;

  .set-con-item {
    width: 50%;
    .unit {
      padding-left: 10px;
      color: #ffffff;
    }
    .set-con-char {
      color: #ffffff;
      padding-right: 3px;
    }
    .set-con-val {
      margin-top: 12px;
    }
  }
  ::v-deep span.el-checkbox__label {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.7px;
  }
  ::v-deep .el-form-item__label {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.7px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }
}

.throld-table {
  width: 100%;
  border: 1px solid #3b68a9;
  td {
    height: 32px;
    border: 1px solid #3b68a9;
    color: #ffffff;
    padding-left: 5px;
    margin: 0;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.7px;
  }
}

.throld-table .center-tr td {
  height: 40px;
  line-height: 40px;
  border-left: 0;
  border-bottom: 0;
}
.throld-table .center-tr td:last-child {
  border-right: 0;
}
.throld-table .center-tr td:nth-child(2n-1) {
  text-align: center;
  width: 100px;
}
.throld-table .center-tr td:nth-child(2n) {
  text-align: left;
  padding-left: 15px;
}
.center-tr:first-child td {
  border-top: 0;
}

.threshold-val {
  ::v-deep span.el-checkbox__label {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.7px;
  }
}
::v-deep div.el-dialog .el-input {
  width: 110px;
}
.add-dialog ::v-deep .el-table__row.hui1 {
  background: #163c77 !important;
}

.add-dialog ::v-deep .el-table__row.lv1 {
  background: #123367 !important;
}


.add-dialog ::v-deep .hui2 {
  background: #0A2E63 !important;
  height: 40px;
}
.add-dialog::v-deep .lv2 {
  background: #0D336B !important;
  height: 40px;
}

::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
}
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}
::v-deep div.el-dialog {
  width: 610px !important;
  border: none;
  background-image: linear-gradient(0deg, #0a173d 0%, #124286 100%);
}
::v-deep .el-dialog__body {
  margin: 0 20px;
  padding: 0;
  padding-top: 0 !important;
}
::v-deep div.el-dialog .el-dialog__header {
  position: relative;
}
::v-deep div.el-dialog .el-dialog__header::before {
  position: absolute;
  content: "";
  height: 100%;
  top: 0px;
  left: 20px;
  right: 20px;
  width: 440px;
}
.mt16 {
  margin-top: 16px;
}
.threshold-btn {
  margin-top: 35px;
  justify-content: center;
  padding-bottom: 35px;
}
.threshold-line {
  //   border-bottom: 1px solid rgba(151, 151, 151, 0.27);
}
.point-info {
  padding-left: 13px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:nth-child(1) {
    font-size: 14px;
    color: #ffffff;
  }
  span:nth-child(2) {
    font-size: 12px;
    color: #32c5ff;
    cursor: pointer;
  }
}
.threshold-val {
  //   padding-left: 13px;
  color: #ffffff;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  margin-top: 26px;
}
.point-info::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 7px;
  left: 0;
  background: #2667db;
  top: 26px;
}
.point-line::after {
  position: absolute;
  content: "";
  right: 0;
  left: 0;
  top: 30px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.27);
}
::v-deep .el-dialog div.el-table th {
  height: 40px !important;
  background-color: transparent !important;
  font-size: 12px !important;
}
::v-deep .el-dialog div.el-table td {
  font-size: 12px !important;
}
::v-deep .el-dialog .el-table__body-wrapper {
  max-height: 255px;
  overflow-y: scroll;
}

::v-deep .el-table th.el-table__cell {
  //   text-align: center;
}

::v-deep .el-table th.el-table__cell.is-leaf.text-left {
  text-align: left;
}
::v-deep .el-table td.el-table__cell.text-left {
  text-align: left;
}
.text-left {
  text-align: left;
}
::v-deep div.el-table td {
  height: 32px;
}

::v-deep .el-table .el-table__cell {
  padding: 0;
}
.single {
  margin-top: 20px;
  margin-left: 14px;
}
.info-table {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #3b68a9;
  border-top: 0;
  border-right: 0;
  font-size: 12px;
  color: #fff;
  .info-item-message { 
      max-width: 50%;
    }
  .info-item {
    display: flex;
    .info-item-title {
      padding: 11px 0;
      width: 97px;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info-item-title2 {
      padding: 30px 0;
      width: 97px;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info-item-content {
      width: 180.5px;
      padding: 11px 0;
      text-align: center;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info-item-content2 {
      display: flex;
      width: 458px;
      padding: 11px 0;
      text-align: left;
      padding-left: 20px;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
    }
    .info-item-content3 {
      width: 555px;
      padding: 11px 0;
      text-align: center;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #32c5ff;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
    .info-item-content4 {
      width: 388px;
      padding: 11px 0;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      padding-left: 20px;
      padding-right: 20px;
      .condition-item {
        display: flex;
        align-items: center;
        .condition-item-title {
          width: 58px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
    .info-item-action {
      width: 70px;
      border-top: 1px solid #3b68a9;
      border-right: 1px solid #3b68a9;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .chaoxian-input {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;
    }
    // .info-item-content:nth-child(2n) {
    //   border-right: 0;
    // }
  }
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
::v-deep .info-table div.el-input .el-input__inner {
  border: none;
  background: rgba(15, 48, 104, 0) !important;
  padding-left: 2px;
  color: #fff;
  font-size: 12px !important;
  padding-right: 10px;
}
::v-deep .info-table .el-select .el-input .el-select__caret {
  font-size: 12px;
}
::v-deep .chaoxian-input div.el-input .el-input__inner {
  height: 28px;
  border: 1px solid #3b68a9;
  border-radius: 0;
}

::v-deep .chaoxian-input .el-input {
  width: 44px !important;
}
::v-deep .info-item-content2 .el-input--suffix {
    width: 300px !important;
    border: 1px solid #3b68a9;
    padding: 0 20px;
    padding-right: 10px;
}
::v-deep .info-item-content4 .select1 .el-input {
  width: 275px !important;
  border: 1px solid #3b68a9;
  padding: 0 20px;
  padding-right: 10px;
}
::v-deep .info-item-content4 .select2 .el-input {
  width: 105px !important;
  border: 1px solid #3b68a9;
  padding: 0 20px;
  padding-right: 10px;
}

::v-deep .el-radio__label {
  color: #fff;
}

::v-deep .el-dialog__header{
  background-image: linear-gradient(94deg, #124183 0%, #123682 100%);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #7ca9df99;;
}
::v-deep .el-dialog__headerbtn {
  width: 24px;
  height: 24px;
  background: rgba(204, 218, 255, 0.2);
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  top: 14px;
} 
::v-deep .el-icon-close:before {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
}

::v-deep .el-dialog__body{
  background-image: linear-gradient(0deg, #0A173D 0%, #124286 100%);
  border-radius: 4px;
}
</style>
