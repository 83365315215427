import moment from "moment";
export function formateArr(arr, newArr = []) {
  console.log("arr", arr, "newArr", newArr);
  if (!arr || !arr.length) return;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] && arr[i].length) {
      formateArr(arr[i], newArr);
    } else {
      if (arr[i]) {
        newArr.push(arr[i]);
      }
    }
  }
  return newArr;
}

export function formateDay(type = "1") {
  let num = "";
  switch (type) {
    case "1":
      num = 24 * 3600 * 1000;
      break;
    case "2":
      num = 7 * 24 * 3600 * 1000;
      break;
    case "3":
      num = 30 * 24 * 3600 * 1000;
  }
  let t = new Date().getTime();
  let d1 = moment(new Date(t)).format("YYYY-MM-DD HH:mm");
  let d2 = moment(new Date(t - num)).format("YYYY-MM-DD HH:mm");
  let d3 = moment(new Date(t - 2 * num)).format("YYYY-MM-DD HH:mm");
  let d4 = moment(new Date(t - 3 * num)).format("YYYY-MM-DD HH:mm");
  let d5 = moment(new Date(t - 4 * num)).format("YYYY-MM-DD HH:mm");
  return [d1, d2, d3, d4, d5];
}
